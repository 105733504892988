import {
  addDays,
  addMilliseconds,
  addMinutes,
  addMonths,
  addWeeks,
  differenceInHours,
  differenceInMilliseconds,
  differenceInMinutes,
  endOfDay,
  endOfMonth,
  endOfWeek,
  format,
  isSameDay,
  lightFormat,
  startOfDay,
  startOfMonth,
  subMilliseconds,
  subMonths,
  subWeeks,
} from 'date-fns'

const Holidays = require('date-holidays')

export interface DateRange {
  from: Date
  to: Date
}

export interface HourMinuteSecond {
  hour: number
  minute: number
  second: number
}

export interface TimeRange {
  from: number
  to: number
}

export class DateUtils {
  private static MILLISECONDS_IN_SECOND: number = 1000
  private static SECONDS_IN_MINUTE: number = 60
  private static MILLISECONDS_IN_MINUTE: number =
    DateUtils.MILLISECONDS_IN_SECOND * DateUtils.SECONDS_IN_MINUTE
  private static MINUTES_IN_HOUR: number = 60
  private static MILLISECONDS_IN_HOUR: number =
    DateUtils.MILLISECONDS_IN_MINUTE * DateUtils.MINUTES_IN_HOUR
  private static TAG = 'DateUtils'

  constructor() {}

  static isInMinuteWindow(
    testedDate: Date,
    centerDate: Date,
    windowMinutes: number,
  ) {
    const lowerBoundary = addMinutes(centerDate, -windowMinutes / 2)
    const upperBoundary = addMinutes(centerDate, windowMinutes / 2)

    return (
      testedDate.getTime() >= lowerBoundary.getTime() &&
      testedDate.getTime() <= upperBoundary.getTime()
    )
  }

  static zeropad(num?: number, characterCount: number = 2) {
    if (num == undefined) return num

    const truncNum = Math.trunc(num)

    if (truncNum >= Math.pow(10, characterCount)) {
      return truncNum
    }
    return (Array(characterCount).join('0') + truncNum).slice(-characterCount)
  }

  /**
   * To be used for syncfusion timepicker which expects time in the format 1:00 (with CS locale)
   */
  static dateToHhColonMm(d: Date): string {
    return this.zeropad(d.getHours(), 2) + ':' + this.zeropad(d.getMinutes(), 2)
  }

  static hoursToHhColonMm(hours: number): string {
    const h = Math.trunc(hours)
    const m = (hours - h) * 60
    return h + ':' + DateUtils.zeropad(m, 2)
  }

  static formatAsUtcYMD(date: Date): string {
    return '' + date.getUTCFullYear() + date.getUTCMonth() + date.getUTCDate()
  }

  /**
   * To a Date object, sets time parsed from a string in format "HH:mm"
   */
  static setTime(d: Date, timeString: string): Date {
    let parts, hours, minutes, date

    const timeRegex = /(\d+)\.(\d+) (\w+)/

    parts = timeString.match(timeRegex)
    hours = /am/i.test(parts[3])
      ? (function (am) {
          return am < 12 ? am : 0
        })(parseInt(parts[1], 10))
      : (function (pm) {
          return pm < 12 ? pm + 12 : 12
        })(parseInt(parts[1], 10))

    minutes = parseInt(parts[2], 10)

    date = new Date(d.getTime())
    date.setHours(hours)
    date.setMinutes(minutes)

    return date
  }

  static getTodayStartEnd(): DateRange {
    return { from: this.getTodayStart(), to: this.getTodayEnd() }
  }

  static getTomorrowStartEnd(): DateRange {
    const today = this.getTodayStartEnd()
    return this.addDaysToDateRange(today, 1)
  }

  static getYesterdayEnd(): Date {
    let yesterdayEnd = new Date()
    yesterdayEnd.setHours(0, 0, 0, 0)
    yesterdayEnd = addMilliseconds(yesterdayEnd, -1)
    return yesterdayEnd
  }

  static getTodayStart(): Date {
    return this.getDayStart(new Date())
  }

  static getTodayEnd(): Date {
    return this.getDayEnd(new Date())
  }

  static getDayStart(date: Date): Date {
    const d = new Date(date)
    d.setHours(0, 0, 0, 0)
    return d
  }

  static getDayEnd(date: Date): Date {
    const d = new Date(date)
    d.setHours(23, 59, 59, 999)
    return d
  }

  static getWeekStart(date: Date, firstDayOfWeekIndex = 1) {
    const dayOfWeek = date.getDay()
    const firstDayOfWeek = new Date(date)
    const diff =
      dayOfWeek >= firstDayOfWeekIndex
        ? dayOfWeek - firstDayOfWeekIndex
        : 6 - dayOfWeek

    firstDayOfWeek.setDate(date.getDate() - diff)
    firstDayOfWeek.setHours(0, 0, 0, 0)

    return firstDayOfWeek
  }

  static getNextWeekStart(date: Date, firstDayOfWeekIndex = 1) {
    return this.getWeekStart(this.addWeeks(date, 1))
  }

  static getWeekEnd(date: Date) {
    return subMilliseconds(this.getNextWeekStart(date), 1)
  }

  static getNextNextWeekStart(date: Date) {
    return this.getWeekStart(this.addWeeks(date, 2))
  }

  static getNextWeekEnd(date: Date) {
    return subMilliseconds(this.getNextNextWeekStart(date), 1)
  }

  static addWeeks(date: Date, numWeeks: number): Date {
    const d = new Date(date)
    d.setDate(d.getDate() + numWeeks * 7)
    return d
  }

  static getMinDate(dateArray: Date[]) {
    return dateArray.reduce((a, b) => (a < b ? a : b))
  }

  static getMaxDate(dateArray: Date[]) {
    return dateArray.reduce((a, b) => (a > b ? a : b))
  }

  static nowMinusMinute(minutes: number) {
    const now = new Date(Date.now())
    now.setMinutes(now.getMinutes() - minutes)
    return now
  }

  static nowMinusDays(days: number) {
    const now = new Date(Date.now())
    now.setDate(now.getDate() - days)
    return now
  }

  /*
  How many minutes are left from now to a given dateTime
   */
  static minutesToDateTime(dateTime: Date) {
    const date2ts = dateTime.getTime()
    const date1ts = Date.now()

    const diff = (date2ts - date1ts) / this.MILLISECONDS_IN_MINUTE

    return diff
  }

  static nextMondayStart(): Date {
    return this.nextDayAndTime(1, 0, 0)
  }

  static minutesToNextMonday(): number {
    return this.minutesToDateTime(this.nextMondayStart())
  }

  // day: 0=Sunday, 1=Monday...4=Thursday...
  static nextDayAndTime(dayOfWeek, hour, minute): Date {
    const now = new Date()
    const result = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() + ((7 + dayOfWeek - now.getDay()) % 7),
      hour,
      minute,
    )

    if (result < now) {
      result.setDate(result.getDate() + 7)
    }

    return result
  }

  static subtractDateRangeFromDateRanges(
    initialRangeSet: DateRange[],
    rangeToSubtract: DateRange,
  ): DateRange[] {
    const resultRangeSet: DateRange[] = []
    // iterate over initialRangeSet
    initialRangeSet.forEach((r) => {
      // check each for overlap with rangeToSubtract
      if (this.dateRangeOverlaps(r, rangeToSubtract)) {
        // if overlaps, then subtract from this range chunk
        resultRangeSet.push(
          ...this.subtractDateRangeFromDateRange(r, rangeToSubtract),
        )
      } else {
        resultRangeSet.push(r)
      }
    })
    return resultRangeSet
  }

  /**
   * Checks whether two ranges overlap by any part, optionally even only by edges.
   */
  static dateRangeOverlaps(
    range1: DateRange,
    range2: DateRange,
    includeEdges: boolean = false,
  ): boolean {
    if (includeEdges) {
      return range1.from <= range2.to && range1.to >= range2.from
    }
    return range1.from < range2.to && range1.to > range2.from
  }

  static dateRangesAnyOverlap(ranges1: DateRange[], ranges2: DateRange[]) {
    for (const r1 of ranges1) {
      for (const r2 of ranges2) {
        if (this.dateRangeOverlaps(r1, r2)) {
          // console.log('overlap', r1, r2)
          return true
        }
      }
    }
    return false
  }

  private static subtractDateRangeFromDateRange(
    r: DateRange,
    rangeToSubtract: DateRange,
  ) {
    const resultRangeSet: DateRange[] = []

    if (rangeToSubtract.from.getTime() <= r.from.getTime()) {
      resultRangeSet.push({ from: rangeToSubtract.to, to: r.to })
    }
    if (rangeToSubtract.from > r.from && rangeToSubtract.to < r.to) {
      resultRangeSet.push({ from: r.from, to: rangeToSubtract.from })
      resultRangeSet.push({ from: rangeToSubtract.to, to: r.to })
    }
    if (rangeToSubtract.to.getTime() >= r.to.getTime()) {
      resultRangeSet.push({ from: r.from, to: rangeToSubtract.from })
    }
    return resultRangeSet
  }

  static millisecondsToHours(ms: number) {
    return ms / this.MILLISECONDS_IN_HOUR
  }

  static millisecondsToMinutes(ms: number) {
    return ms / this.MILLISECONDS_IN_MINUTE
  }

  static minutesToHours(minutes: number, digits = 2) {
    if (!minutes) return undefined
    return Number((Number(minutes) / this.MINUTES_IN_HOUR).toFixed(digits))
  }

  static hoursToMinutes(hours: number, digits = 2) {
    if (!hours) return undefined
    return Number((hours * this.MINUTES_IN_HOUR).toFixed(digits))
  }

  static getThisMonthStartEnd(): DateRange {
    return {
      from: this.getThisMonthStartDate(),
      to: this.getMonthEnd(this.getThisMonthStartDate()),
    }
  }

  static getThisMonthStartDate() {
    const date = new Date()
    return new Date(date.getFullYear(), date.getMonth(), 1)
  }

  static getLastMonthStartDate() {
    const date = new Date()
    return new Date(date.getFullYear(), date.getMonth() - 1, 1)
  }

  static getLastMonthStartEnd() {
    return {
      from: this.getLastMonthStartDate(),
      to: this.getMonthEnd(this.getLastMonthStartDate()),
    }
  }

  static getNextMonthStartDate() {
    const date = new Date()
    return new Date(date.getFullYear(), date.getMonth() + 1)
  }

  static getMonthStart(date: Date): Date {
    return startOfMonth(date)
  }

  static getMonthEnd(date: Date): Date {
    return endOfMonth(date)
  }

  static getWeekStartEnd(dateInsideWeek: Date): DateRange {
    const firstDayOfWeek = DateUtils.getWeekStart(dateInsideWeek)
    const lastDayOfWeek = new Date(firstDayOfWeek)
    lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 7)

    return { from: firstDayOfWeek, to: lastDayOfWeek }
  }

  static getThisWeekStartEnd(): DateRange {
    return this.getWeekStartEnd(DateUtils.getWeekStart(new Date()))
  }

  static getNextWeekStartEnd(): DateRange {
    return this.getWeekStartEnd(DateUtils.getNextWeekStart(new Date()))
  }

  static getLastWeekStartEnd(): DateRange {
    return this.getWeekStartEnd(subWeeks(new Date(), 1))
  }

  static getNextNextWeekStartEnd(): DateRange {
    return this.getWeekStartEnd(DateUtils.getNextNextWeekStart(new Date()))
  }

  static shiftDateRangeByMonths(
    range: DateRange,
    shiftMonths: number,
  ): DateRange {
    const shiftedFrom = new Date(range.from)
    const shiftedTo = new Date(range.to)

    shiftedFrom.setMonth(range.from.getMonth() + shiftMonths)
    shiftedTo.setMonth(range.to.getMonth() + shiftMonths)

    return { from: shiftedFrom, to: shiftedTo }
  }

  static isInRange(date: Date, range: DateRange, includeEdges = true): boolean {
    if (includeEdges) {
      return (
        date.getTime() >= range.from.getTime() &&
        date.getTime() <= range.to.getTime()
      )
    } else {
      return (
        date.getTime() > range.from.getTime() &&
        date.getTime() < range.to.getTime()
      )
    }
  }

  /**
   * Checks if the innerRange is within the outerRange.
   *
   * @param {IDateRange} innerRange - The inner date range to be checked.
   * @param {IDateRange} outerRange - The outer date range to compare with.
   * @return {boolean} - True if the innerRange is within the outerRange, false otherwise.
   */
  static isRangeWithinRange(innerRange: DateRange, outerRange: DateRange) {
    return (
      innerRange.from.getTime() >= outerRange.from.getTime() &&
      innerRange.to.getTime() <= outerRange.to.getTime()
    )
  }

  static isOutsideRange(
    date: Date,
    range: DateRange,
    includeEdges = true,
  ): boolean {
    if (includeEdges) {
      return (
        date.getTime() <= range.from.getTime() ||
        date.getTime() >= range.to.getTime()
      )
    } else {
      return (
        date.getTime() < range.from.getTime() ||
        date.getTime() > range.to.getTime()
      )
    }
  }

  static subtractDaysFromDate(date: Date, days: number) {
    const d = new Date(date)
    d.setDate(d.getDate() - days)
    return d
  }

  static addDaysToDateRange(range: DateRange, days: number): DateRange {
    return { from: addDays(range.from, days), to: addDays(range.to, days) }
  }

  static addMonthsToDateRange(range: DateRange, months: number): DateRange {
    return {
      from: addMonths(range.from, months),
      to: addMonths(range.to, months),
    }
  }

  static addDays(date: Date, days: number) {
    const d = new Date(date)
    d.setDate(d.getDate() + days)
    return d
  }

  /**
   * Returns only time portion of a date in format HHMM, as a number
   * @param date
   * @param utc
   */
  static getMilitaryTime(date: Date, utc: boolean = true): number {
    if (utc) {
      return date.getUTCHours() * 100 + date.getUTCMinutes()
    } else {
      return date.getHours() * 100 + date.getMinutes()
    }
  }

  static militaryTimeToTodayUtc(time: number) {
    const d = new Date()
    d.setUTCHours(Math.floor(time / 100), time % 100, 0)
    return d
  }

  static militaryTimeToToday(time: number) {
    const d = new Date()
    d.setHours(Math.floor(time / 100), time % 100, 0)
    return d
  }

  static durationMs(dateStart: Date, dateEnd: Date) {
    return dateEnd.getTime() - dateStart.getTime()
  }

  static differenceOfHoursInMs(dStart: Date, dEnd: Date) {
    const dEndWithdStartYearMonthDay = new Date(dEnd)

    dEndWithdStartYearMonthDay.setFullYear(
      dStart.getFullYear(),
      dStart.getMonth(),
      dStart.getDate(),
    )

    return differenceInMilliseconds(dEndWithdStartYearMonthDay, dStart)
  }

  static isInPast(date: Date) {
    return date < DateUtils.nowMinusMinute(1)
  }

  static parseDateFromStringOrTimestamp(birthDate: number | string | Date) {
    if (isNaN(Number(birthDate))) {
      // Is string date
      return new Date(birthDate)
    } else {
      // Is timestamp
      return new Date(Number(birthDate))
    }
  }

  static canBeParsedToDate(dateStr: string | Date): boolean {
    if (dateStr === null || dateStr === undefined) return false
    return !isNaN(new Date(dateStr).getDate())
  }

  static differenceInHoursDirectional(dateFirst: Date, dateSecond: Date) {
    const diff = differenceInHours(dateFirst, dateSecond)

    if (dateFirst < dateSecond || diff == 0) return diff
    return -diff
  }

  static hhColonMmToHourMinuteSecondObject(
    hhColonMm: string,
  ): HourMinuteSecond {
    if (!hhColonMm || this.canBeParsedToDate(hhColonMm)) return null
    const values = hhColonMm.split(':')
    return {
      hour: Number(values[0]),
      minute: Number(values[1]),
      second: 0,
    }
  }

  static addMinutesToHhColonMm(hhColonMm: string, addMinutes: number): string {
    if (!hhColonMm) return null

    const minutes =
      Number(hhColonMm.substr(0, 2)) * 60 + Number(hhColonMm.substr(3, 2))
    const res = minutes + addMinutes

    return this.minutesToHhColonMm(res)
  }

  static minutesToHhColonMm(minutes: number, zeropadHours = true) {
    const h = Math.trunc(minutes / 60)
    const m = minutes - 60 * h

    const paddedH = zeropadHours ? this.zeropad(h, 2) : h

    return paddedH + ':' + this.zeropad(m, 2)
  }

  static diffHHColonMMInMinutes(time1: string, time2: string): number {
    const hms1 = this.hhColonMmToHourMinuteSecondObject(time1)
    const hms2 = this.hhColonMmToHourMinuteSecondObject(time2)

    const minutes1 = hms1.hour * 60 + hms1.minute
    const minutes2 = hms2.hour * 60 + hms2.minute

    return minutes2 - minutes1
  }

  static localHourToUtc(hour: number) {
    const d = new Date()
    const minuteOffset = d.getTimezoneOffset()

    if (minuteOffset % 60 === 0) {
      return hour + minuteOffset / 60
    }
    console.error(
      this.TAG,
      'localHourToUtc(), local offset in minutes is not divisible by 60',
      minuteOffset,
      d,
    )
  }

  static coerceToHhColonMm(dateOrHhColonMm: string | Date): string {
    if (this.canBeParsedToDate(dateOrHhColonMm)) {
      return this.dateToHhColonMm(new Date(dateOrHhColonMm))
    }
    return dateOrHhColonMm as string
  }

  static getThisWeekDatesString() {
    return this.getRangeDatesString(DateUtils.getThisWeekStartEnd())
  }

  static getNextWeekDatesString() {
    return this.getRangeDatesString(DateUtils.getNextWeekStartEnd())
  }

  static getLastWeekDatesString() {
    return this.getRangeDatesString(DateUtils.getLastWeekStartEnd())
  }

  static getNextNextWeekDatesString() {
    return this.getRangeDatesString(DateUtils.getNextNextWeekStartEnd())
  }

  static getRangeDatesString(range: DateRange) {
    const startDay = range.from.getDate()
    const startMonth = range.from.getMonth() + 1
    const endDay = range.to.getDate()
    const endMonth = range.to.getMonth() + 1

    return `${startDay}.${startMonth}. - ${endDay}.${endMonth}.`
  }

  static rangeToDays(range: DateRange): DateRange[] {
    if (isSameDay(range.from, range.to)) return [range]

    const ranges: DateRange[] = []
    // zacatek - nasledujici pulnoc
    let currentDateFrom = range.from
    let currentDateTo = endOfDay(range.from)

    while (currentDateTo < range.to) {
      ranges.push({
        from: currentDateFrom,
        to: currentDateTo,
      })
      currentDateFrom = addMilliseconds(currentDateTo, 1)
      currentDateTo = addDays(currentDateTo, 1)
    }
    ranges.push({
      from: currentDateFrom,
      to: range.to,
    })

    return ranges
  }

  // Return an array of days (as Date objects) within the specified range.
  //
  static getDaysInRange(range: DateRange): Date[] {
    const start = this.getDayStart(range.from)
    const end = this.getDayStart(range.to)
    const dates = []

    for (const curr = start; curr <= end; curr.setDate(curr.getDate() + 1)) {
      dates.push(new Date(curr))
    }
    return dates
  }

  static getThisWeekDateRanges() {
    const range = this.rangeToDays(this.getThisWeekStartEnd())
    range.pop() // removes sun 23:59:59-00:00:00
    return range
  }

  static getNextWeekDateRanges() {
    const range = this.rangeToDays(this.getNextWeekStartEnd())
    range.pop() // removes sun 23:59:59-00:00:00
    return range
  }

  static abbrToRange(toParse: RangeAbbreviation | DateRange): DateRange {
    switch (toParse) {
      case 'today':
        return { from: this.getYesterdayEnd(), to: this.getTodayEnd() }
      case 'thisWeek':
        return this.getThisWeekStartEnd()
      case 'nextWeek':
        return this.getNextWeekStartEnd()
      default:
        if (typeof toParse !== 'string') {
          return toParse // Is a DateRange for sure
        }
        throw new Error(`Invalid argument ${toParse}`)
    }
  }

  static isSameRange(range1: DateRange, range2: DateRange) {
    return (
      range1.from.getTime() === range2.from.getTime() &&
      range1.to.getTime() === range2.to.getTime()
    )
  }

  static translateShortWeekdayToCZ(dayOfWeek: string) {
    switch (dayOfWeek) {
      case 'Mon':
        return 'Po'
      case 'Tue':
        return 'Út'
      case 'Wed':
        return 'St'
      case 'Thu':
        return 'Ct'
      case 'Fri':
        return 'Pá'
      case 'Sat':
        return 'So'
      case 'Sun':
        return 'Ne'
      default:
        return dayOfWeek
    }
  }

  static isWeekendOrHoliday(date: Date) {
    return this.isWeekend(date) || this.isHoliday(date)
  }

  static isWeekend(date: Date) {
    return date.getDay() == 6 || date.getDay() == 0
  }

  static isHoliday(date: Date) {
    const holidays = new Holidays('CZ')

    const holiday = holidays.isHoliday(date)
    console.log(holiday)
    if (!holiday) return false
    if (Array.isArray(holiday) && holiday.some((h) => h.type === 'public')) {
      return true
    }
    return false
  }

  static safeFormat(
    date: Date | number,
    fmt: string,
    fallback = '',
    options?: {
      locale?: Locale
      weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6
      firstWeekContainsDate?: number
      useAdditionalWeekYearTokens?: boolean
      useAdditionalDayOfYearTokens?: boolean
    },
  ) {
    if (!date) return fallback
    return format(date, fmt, options)
  }

  static getTimezoneDifference(d1: Date, d2: Date): number {
    return (d1.getTimezoneOffset() - d2.getTimezoneOffset()) / 60
  }

  static toPreviousMonth(date: Date): DateRange {
    const monthStart = this.getMonthStart(date)
    const previousMonthStart = subMonths(monthStart, 1)

    return {
      from: previousMonthStart,
      to: this.getMonthEnd(previousMonthStart),
    }
  }

  static toNextMonth(date: Date): DateRange {
    const monthStart = this.getMonthStart(date)
    const nextMonthStart = addMonths(monthStart, 1)

    return { from: nextMonthStart, to: this.getMonthEnd(nextMonthStart) }
  }

  static toPreviousWeek(date: Date): DateRange {
    const weekStart = this.getWeekStart(date)
    const previousWeekStart = subWeeks(weekStart, 1)

    return {
      from: previousWeekStart,
      to: endOfWeek(previousWeekStart, { weekStartsOn: 1 }),
    }
  }

  static toNextWeek(date: Date): DateRange {
    const weekStart = this.getWeekStart(date)
    const nextWeekStart = addWeeks(weekStart, 1)

    return {
      from: nextWeekStart,
      to: endOfWeek(nextWeekStart, { weekStartsOn: 1 }),
    }
  }

  static rangeToHhColonMm(range: DateRange, separator = '-') {
    return `${this.dateToHhColonMm(range.from)}${separator}${this.dateToHhColonMm(range.to)}`
  }

  static differenceInMinutesWithZeroedSeconds(
    dateLater: Date,
    dateSooner: Date,
  ) {
    const d1 = new Date(dateLater)
    const d2 = new Date(dateSooner)

    d1.setSeconds(0, 0)
    d2.setSeconds(0, 0)

    return differenceInMinutes(d1, d2)
  }

  static getMonthRange(date: Date) {
    return { from: this.getMonthStart(date), to: this.getMonthEnd(date) }
  }

  /**
   * Returns date range as string in format d. M. y - d. M. y
   */
  static humanizeDateRangeDays(dateRange: DateRange) {
    return `${lightFormat(dateRange.from, 'd. M. y')} - ${lightFormat(dateRange.to, 'd. M. y')}`
  }

  static getWeekdayName(date: Date): string {
    const dayFormatter = new Intl.DateTimeFormat('cs-CZ', { weekday: 'short' })
    const day = dayFormatter.format(date)
    return day.charAt(0).toUpperCase() + day.slice(1) // first char to uppercase
  }

  static getMonthName(date: Date): string {
    const monthFormatter = new Intl.DateTimeFormat('cs-CZ', { month: 'long' })
    return monthFormatter.format(date)
  }

  static getDateInHumanReadable(date: Date): string {
    const formatter = new Intl.DateTimeFormat('cs-CZ', {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    })
    return formatter.format(date)
  }

  static startOfDay(date: Date): Date {
    return startOfDay(date)
  }
}

export type RangeAbbreviation = 'today' | 'thisWeek' | 'nextWeek'
